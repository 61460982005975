import {
  Resource,
  List,
  Datagrid,
  BulkDeleteButton,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  FunctionField,
} from "react-admin";
import { CrudPermissions } from "../../auth/crud-premissions";
import WebIcon from "@mui/icons-material/Web";
import { PaidSourceInput } from "./paid-source-input";
import { getPaidSourceName } from "./paid-source-name";

export const TrackedUrlResource = (perms: CrudPermissions) => {
  return (
    <Resource
      name="tracked-url"
      key={"tracked-url"}
      options={{
        label: "Tracked URLs",
      }}
      list={TrackedUrlList(perms)}
      edit={perms.update ? TrackedUrlEdit : undefined}
      create={perms.create ? TrackedUrlCreate : undefined}
      icon={WebIcon}
    />
  );
};

export const TrackedUrlList = (perms: CrudPermissions) => {
  return (
    <List>
      <Datagrid
        rowClick={perms.update ? "show" : false}
        bulkActionButtons={perms.delete ? <BulkDeleteButton /> : false}
      >
        <FunctionField
          label="Paid Source"
          // eslint-disable-next-line
          render={(record) => getPaidSourceName(record.paidSource)}
        />
        <TextField source="urlPrefix" label="URL Prefix" />
      </Datagrid>
    </List>
  );
};

export const TrackedUrlEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <PaidSourceInput />
        <TextInput source="urlPrefix" label="URL Prefix" />
      </SimpleForm>
    </Edit>
  );
};

export const TrackedUrlCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <PaidSourceInput />
        <TextInput source="urlPrefix" label="URL Prefix" />
      </SimpleForm>
    </Create>
  );
};
