import {
  Resource,
  List,
  Datagrid,
  BulkDeleteButton,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  FunctionField,
} from "react-admin";
import { CrudPermissions } from "../../auth/crud-premissions";
import PhoneIcon from "@mui/icons-material/Phone";
import { PaidSourceInput } from "./paid-source-input";
import { getPaidSourceName } from "./paid-source-name";

export const TrackedCallResource = (perms: CrudPermissions) => {
  return (
    <Resource
      name="tracked-call"
      key={"tracked-call"}
      options={{
        label: "Tracked Calls",
      }}
      list={TrackedCallList(perms)}
      edit={perms.update ? TrackedCallEdit : undefined}
      create={perms.create ? TrackedCallCreate : undefined}
      icon={PhoneIcon}
    />
  );
};

export const TrackedCallList = (perms: CrudPermissions) => {
  return (
    <List>
      <Datagrid
        rowClick={perms.update ? "show" : false}
        bulkActionButtons={perms.delete ? <BulkDeleteButton /> : false}
      >
        <FunctionField
          label="Paid Source"
          // eslint-disable-next-line
          render={(record) => getPaidSourceName(record.paidSource)}
        />
        <TextField source="destinationPhone" label="Destination Phone (E164)" />
      </Datagrid>
    </List>
  );
};

export const TrackedCallEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <PaidSourceInput />
        <TextInput source="destinationPhone" label="Destination Phone (E164)" />
      </SimpleForm>
    </Edit>
  );
};

export const TrackedCallCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <PaidSourceInput />
        <TextInput source="destinationPhone" label="Destination Phone (E164)" />
      </SimpleForm>
    </Create>
  );
};
