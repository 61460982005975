import { PaidSourceResource } from "@arrowsup/platform-dtos";

export const getPaidSourceName = (p: PaidSourceResource): string => {
  let name = p.adSource;

  const detailFields = [p.campaignId, p.adGroupId, p.adId];

  for (const field of detailFields) {
    if (!field) {
      break; // Don't show past the first empty field.
    }

    name += " > " + field;
  }

  return name;
};
