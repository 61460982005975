import {
  BulkDeleteButton,
  Create,
  Datagrid,
  Edit,
  List,
  Resource,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { CrudPermissions } from "../../auth/crud-premissions";
import PaymentIcon from "@mui/icons-material/Payment";

export const PaidSourceResource = (perms: CrudPermissions) => {
  return (
    <Resource
      name="paid-source"
      key={"paid-source"}
      options={{
        label: "Paid Sources",
      }}
      list={PaidSourceList(perms)}
      edit={perms.update ? PaidSourceEdit : undefined}
      create={perms.create ? PaidSourceCreate : undefined}
      icon={PaymentIcon}
    />
  );
};

export const PaidSourceList = (perms: CrudPermissions) => {
  return (
    <List>
      <Datagrid
        rowClick={perms.update ? "show" : false}
        bulkActionButtons={perms.delete ? <BulkDeleteButton /> : false}
      >
        <TextField source="id" label="Source ID" />
        <TextField source="adSource" label="Ad Source" />
        <TextField source="campaignId" label="Campaign ID" />
        <TextField source="adGroupId" label="Ad Group ID" />
        <TextField source="adId" label="Ad ID" />
      </Datagrid>
    </List>
  );
};

const AdSourceInput: React.FC = () => {
  return (
    <SelectInput
      source="adSource"
      label="Ad Source"
      helperText="Which Ad network?"
      choices={["google", "meta"].map((v) => ({
        id: v,
        name: v,
      }))}
      fullWidth
      required
      sx={{ pb: 2 }}
    />
  );
};

export const CampaignIdInput: React.FC = () => {
  return (
    <TextInput
      source="campaignId"
      label="Campaign ID"
      helperText="Optional campaign ID."
      fullWidth
      sx={{ pb: 2 }}
    />
  );
};

export const AdGroupId: React.FC = () => (
  <TextInput
    source="adGroupId"
    label="Ad Group ID"
    helperText="Optional Ad Group ID."
    fullWidth
    sx={{ pb: 2 }}
  />
);

export const AdId: React.FC = () => (
  <TextInput
    source="adId"
    label="Ad ID"
    helperText="Optional Ad ID."
    fullWidth
    sx={{ pb: 2 }}
  />
);

export const PaidSourceEdit: React.FC = () => {
  return (
    <div>
      <Edit redirect="list">
        <SimpleForm>
          <AdSourceInput />
          <CampaignIdInput />
          <AdGroupId />
          <AdId />
        </SimpleForm>
      </Edit>
    </div>
  );
};

export const PaidSourceCreate: React.FC = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <AdSourceInput />
        <CampaignIdInput />
        <AdGroupId />
        <AdId />
      </SimpleForm>
    </Create>
  );
};
