import { SelectInput, useDataProvider } from "react-admin";
import { useQuery } from "@tanstack/react-query";
import { AuResourceName, PaidSourceResource } from "@arrowsup/platform-dtos";
import { getPaidSourceName } from "./paid-source-name";

const usePaidSourceInput = () => {
  const dataProvider = useDataProvider();
  return useQuery({
    queryKey: ["paid-source" satisfies AuResourceName],
    queryFn: ({ signal }) =>
      dataProvider.getList<PaidSourceResource>(
        "paid-source" satisfies AuResourceName,
        {
          sort: {
            field: "adSource" satisfies keyof PaidSourceResource,
            order: "ASC",
          },
          pagination: {
            page: 1,
            perPage: 1000,
          },
          signal,
        }
      ),
  });
};

export const PaidSourceInput: React.FC = () => {
  const { data } = usePaidSourceInput();
  return (
    <SelectInput
      source="paidSource.id"
      label="Paid Source"
      helperText="Which Paid Source is this linked to?"
      choices={data?.data.map((v) => {
        const name = getPaidSourceName(v);

        return {
          id: v.id,
          name,
        };
      })}
      fullWidth
      required
    />
  );
};
